import { useState, useEffect } from 'react';

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function useAgentData(initialData) {
  const [associatedAgent, setAssociatedAgent] = useState(initialData ?? '');

  const setDataToSessionStorage = (data) => {
    sessionStorage.setItem('agentData', JSON.stringify(data));
  };

  const getDataFromSessionStorage = () => {
    return JSON.parse(sessionStorage.getItem('agentData'));
  };

  return {
    associatedAgent,
    setAssociatedAgent,
    setAssociatedAgentToSessionStorage: setDataToSessionStorage,
    getAssociatedAgentFromSessionStorage: getDataFromSessionStorage
  };
}
